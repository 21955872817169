// Font Size
$size1: 13px;
$size2: 14px;
$size3: 15px;
$size4: 16px;
$size5: 18px;
$size6: 21px;
$size7: 24px;

$fontFamNunito: 'Nunito Sans';
$fontFamPretendard: 'Pretendard';
$fontFamNanum: 'NanumGothic';

$point_color: #1a73c4;
$f_white: #ffff;

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
}

body {
  color: #333333;
}

body {
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333333;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

::placeholder {
  color: #999999;
  font-size: $size4;
}
