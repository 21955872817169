:root {
  --container-px: 30px;
  @media (min-width: theme('screens.xl')) {
    --container-px: 40px;
  }
}

body {
  font-family: 'Nunito Sans', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}
