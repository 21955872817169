@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    color-scheme: light only;
}

.media .swiper-slide {
    background: none !important;
}

.breakword {
    word-break: break-word;
}

@media (prefers-color-scheme: dark) {
    img, video, iframe {
        filter: invert(100%) hue-rotate(-25deg);
    }
}

@media (prefers-color-scheme: dark) {
    img, video, iframe {
        filter: none
    }
}

 .mobile_sticky {
     position: fixed;
    top: 64px;
    left: 0;
    z-index: 80;
    background: white;
    padding: 23px 30px 0 30px;
}

/* [1] The container
.img-hover-zoom {
    height: 100%;
    overflow: hidden;
}

.img-hover-zoom img {
    transition: transform .5s ease;
}


.img-hover-zoom:hover img {
    transform: scale(1.1);
}
*/
#slide {
    bottom: -90%;
    -webkit-animation: slide 0.9s forwards;
    -webkit-animation-delay: 0s;
    animation: slide 0.9s forwards;
    animation-delay: 0s;
}

@-webkit-keyframes slide {
    100% { bottom: 0; }
}

@keyframes slide {
    100% { bottom: 0; }
}

.forced_css table {
    width: 100% !important;
}

