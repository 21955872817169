.svg-white {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
}


.white-shadows {
  filter: drop-shadow(1px 1px 2px #fff);
}

.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 60px !important;
}


@media screen and (max-width: 480px) {
  .swiper {
    padding-bottom: 45px !important;
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-progressbar-fill {
  transition-timing-function: linear;
}

.swiper-pagination-bullet-active {
  background: #000 !important;
}

.swiper-pagination-bullet { width:12px !important; height: 12px  !important; }
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px !important;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {

}


.btn-shadow{
  box-shadow: 3px 1px 10px 0px rgba(145,40,23,0.43);
  -webkit-box-shadow: 3px 1px 10px 0px rgba(145,40,23,0.43);
  -moz-box-shadow: 3px 1px 10px 0px rgba(145,40,23,0.43);
}

.btn-coupon{
  color:#a68df7;
  background: rgb(252,242,245);
  background: -moz-linear-gradient(0deg, rgba(252,242,245,1) 0%, rgba(254,252,252,1) 45%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(252,242,245,1) 0%, rgba(254,252,252,1) 45%, rgba(255,255,255,1) 100%);
  background: linear-gradient(0deg, rgba(252,242,245,1) 0%, rgba(254,252,252,1) 45%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcf2f5",endColorstr="#ffffff",GradientType=1);
  box-shadow: 2px 1px 10px 0px rgba(166,141,247,0.4);
  -webkit-box-shadow: 2px 1px 10px 0px rgba(212,165,223,0.4);
  -moz-box-shadow: 2px 1px 10px 0px rgba(212,165,223,0.4);
}


.btn-coupon-style2{
  color:#a68df7;
  background: rgb(243,240,253);
  background: -moz-linear-gradient(0deg, rgba(243,240,253,1) 0%, rgba(254,252,252,1) 45%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(243,240,253,1) 0%, rgba(254,252,252,1) 45%, rgba(255,255,255,1) 100%);
  background: linear-gradient(0deg, rgba(243,240,253,1) 0%, rgba(254,252,252,1) 45%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcf2f5",endColorstr="#ffffff",GradientType=1);
  box-shadow: 3px 4px 10px 0px rgba(139,85,253,0.2);
  -webkit-box-shadow: 3px 4px 10px 0px rgba(139,85,253,0.2);
  -moz-box-shadow: 3px 4px 10px 0px rgba(139,85,253,0.2);
}

.introduce_box li {
  background: #f3f3f3;
  width:100%;
  border-radius: 30px;

}

.sns-list img {
  fill : #000;
}

.introduce_box .active{
  border:1px solid #e5a3f9;
  background: rgb(194,152,253);
  background: -moz-linear-gradient(180deg, rgba(194,152,253,1) 63%, rgba(222,181,254,1) 95%);
  background: -webkit-linear-gradient(180deg, rgba(194,152,253,1) 63%, rgba(222,181,254,1) 95%);
  background: linear-gradient(180deg, rgba(194,152,253,1) 63%, rgba(222,181,254,1) 95%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c298fd",endColorstr="#deb5fe",GradientType=1);
}
.introduce_box span {
  border:1px solid #f3f3f3;
  font-weight: bold;
  color: #acacac;
  border-radius: 30px;
}

.introduce_box .active em {
  color:#fff;
  filter: drop-shadow(1px 1px 2px #ae557b);
}

input::placeholder {
  color: #b6b6b6;
}

.notice_navi {
  border-radius: 30px;
  border: 1px solid #e0e0e0;
  overflow: hidden;
}
.notice_navi li {
  border-radius: 30px;
  background: #f3f3f3;
  width:100%;
  color:#bcbcbc;
  border: 1px solid #fff;
}

.notice_navi li .active {
  color:#fff;
  font-weight: 900;
  background: #000;
  border-radius: 30px;
}

.notice_navi li .active em {
  font-weight: 900;
}


.notice_box {
  background: rgb(243,237,255);
  background: -moz-linear-gradient(0deg, rgba(243,237,255,1) 0%, rgba(250,248,255,1) 21%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(243,237,255,1) 0%, rgba(250,248,255,1) 21%, rgba(255,255,255,1) 100%);
  background: linear-gradient(0deg, rgba(243,237,255,1) 0%, rgba(250,248,255,1) 21%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f3edff",endColorstr="#ffffff",GradientType=1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.coupon_shadow {
  background: #FFFFFF;
  background: linear-gradient(to bottom, #FFFFFF 46%, #FCE4EF 82%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(1px 1px 2px #522e89);
}



.with-bts-slide .with-bts-menu li{
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  border-radius: 50%;
  margin: 5px;
  font-family: TmoneyRoundWind ;
  position: relative;
}

.with-bts-slide .with-bts-menu li:before {
  content:'';
  float:left;
  padding-top:93.6%;
}

.with-bts-menu li .char {
  position: absolute;
  bottom:0;
  width:110%;
  height:110%;
}

.bts-container .keen-slider__slide .devnote-text {
  color:#dbdbdb;
  padding-top:10px;
}

.keen-slider__slide.active .devnote-text {
  color:#000;
}


.with-bts-slide .keen-slider__slide:hover  .absolute-books img,
.with-bts-slide .keen-slider__slide.active  .absolute-books img  {
  display: block;
  transform: scale(1.1);
  transition: transform 0.3s linear;
}

.with-bts-slide .keen-slider__slide  img.normal-books,
.with-bts-slide .keen-slider__slide img.normal-books  {
  visibility:hidden;
}


.with-bts-slide .keen-slider__slide.active  {
  padding: 0;
}

.main-withbts-wrap .books-img  {
  background: url('../img/withbts_belt.png') repeat-x 0 bottom;
}

.main-withbts-wrap .books-img:before {
  content:'';
  position: absolute;
  bottom: 0;
  width: 300%;
  height: 100%;
  background: url('../img/withbts_belt.png') repeat-x 0 bottom;
}


.with-bts-slide .keen-slider__slide.active *, .with-bts-slide .keen-slider__slide:hover  *{
   color: #000;

}

.with-bts-slide .keen-slider__slide.active .books-img,
.with-bts-slide .keen-slider__slide.active .books-img:before {

}

@media screen and (min-width: 1000px) {
  .books-fixed-wrapper {
    width: 100%;
    height: 100%;
    background: url('../img/withbts_320_belt.png') repeat 0 340px;
    background-size: auto 400px;
  }
}

@media screen and (max-width: 1000px) {
  .books-fixed-wrapper .books-img {
    background: url('../img/withbts_belt.png') repeat-x 0 bottom;
  }




}




.with-bts-slide .mask{
  mask-image: linear-gradient(87deg, transparent 5%, rgb(0, 0, 0) 55%, rgb(0, 0, 0) 51%, transparent 95%);
}
.with-bts-slide .bts-slide-arrow {
  width: 12px;
}

.media .media-menu {
  display: flex;
}

.media .media-container-ratio:before {
  content:'';
  float:left;
  padding-top:93.6%;
}

.media-menu > div.active {
  color: #df6652;
  text-shadow: #fff 3px 0 5px;
  -webkit-text-shadow:#fff 3px 0 5px;

}

.media-menu > div.active:after {
  background:rgba(0,0,0,0);

}

.media-menu div:before {
  content:'';
  float:left;
  padding-top:60.6%;
}

.media-menu div:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  padding-top: 5px;
  width: 100%;
  height: 100%;
  border-radius: 12%;
}

.media-menu > div {
  position: relative;
  color:#967d30;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: url("../img/media/btn-butter_full.png") no-repeat 100% 100% ;
  background-size: 100%;
  overflow: hidden;
  line-height: 1.1;
}

.stroke-container {
  position: absolute;
  bottom: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  line-height: 1.1;
}

.stroke-container span{
  text-align:center;
  position:absolute;
  font-size: 22px;
}
.stroke-container  span:first-child{
  text-stroke: 5px #fff;
  -webkit-text-stroke: 5px #fff;
  -webkit-text-fill-color: #d7d7d7;
}
.stroke-container  span:last-child{
  color:#d7d7d7;
}
.pagination-container {
  text-align: center;
}

@media screen and (max-width:800px) {

  .stroke-container {
    bottom: 10px;
  }

  .stroke-container span{
    font-size: 18px;
  }
  .stroke-container  span:first-child{
    text-stroke: 5px #fff;
    -webkit-text-stroke: 5px #fff;
    -webkit-text-fill-color: #d7d7d7;
  }
}

@media screen and (max-width: 640px) {
  .stroke-container span{
    font-size: 15px;
  }
}

@media screen and (max-width: 320px) {
  .stroke-container span{
    font-size: 13px;
  }
}


.media_box {
  background: rgb(224,224,224);
  background: -moz-linear-gradient(0deg, rgba(224,224,224,1) 0%, rgba(239,239,239,1) 16%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(224,224,224,1) 0%, rgba(239,239,239,1) 16%, rgba(255,255,255,1) 100%);
  background: linear-gradient(0deg, rgba(224,224,224,1) 0%, rgba(239,239,239,1) 16%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e0e0e0",endColorstr="#ffffff",GradientType=1);
}

.editor_style_wrap th {
  background:#eee;
  border: 1px solid #ddd;

}
.editor_style_wrap h2 {
  font-size: 1.68em;
  margin-bottom: 0.4em;
  font-weight: 400;
}

.editor_style_wrap a {
  color: blue;
}

.editor_style_wrap h3 {
  font-size: 1.36em;
  margin-bottom: 0.2em;
  font-weight: 400;
}
.editor_style_wrap strong {
  font-weight: bold;
}

.editor_style_wrap table {
  width: 100% !important;
}


.editor_style_wrap table td {
  border: 1px solid #ddd;
  word-break: break-word;
  padding: 10px;
}

.editor_style_wrap figure {
  float: none !important;
  display: inline-block !important;
  width:100%;
  margin: 0.1rem !important;
}

.event_notes .dash {
  text-indent: -12px;
  line-height: 1.5;
  font-weight: lighter;
  padding-left: 15px;
  letter-spacing: -0.023rem;
  font-size: 14px;
}
.event_notes  .dash:before {
  content: '- ';
  text-indent: -12px;
}