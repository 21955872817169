/////////// Container
$zindexOvery: 50;
$zindexMobile: 30;
$zindexSliderMenu: 60;
body {
  font-family: $fontFamNunito;
  // background-color: cornflowerblue;
}
// main-banner
.main-banner {
  position: relative;
  background-color: #000000;
  height: 80vh;
  #main-banner-swiper {
    .swiper-wrapper {
      .swiper-slide {
        overflow: hidden;
        background-size: cover;
        background-position: center top;
        &[data-slide-type='video'] {
          background: black;
        }

        video {
          height: 100%;
          overflow: hidden;
          object-fit: cover;
        }

        .slider-text {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-family: $fontFamNunito;
          img {
            width: auto;
            height: auto;
          }
          p {
            color: white;
            &.title {
              font-size: 60px;
              font-weight: 800;
            }
            font-size: 21px;
          }
        }
        .video-js .vjs-big-play-button {
          display: none;
        }
      }
    }
    .swiper-pagination-progressbar {
      top: unset;
      bottom: 0;
      background: none;
      .swiper-pagination-progressbar-fill {
        background: #bc83ff;
      }
    }
    .mb-button-next,
    .mb-button-prev {
      position: absolute;
      display: inline-block;
      width: 30px;
      height: 61px;
      top: 50%;
      transform: translateY(-50%);
      background-size: contain;
      z-index: $index3;
    }
    .mb-button-next {
      right: 10px;
      background-image: url('../img/slider-arrow-right@3x.png');
    }
    .mb-button-prev {
      left: 10px;
      background-image: url('../img/slider-arrow-left@3x.png');
    }
  }
}

@media screen and (max-width: 680px) {
  .footer {
    a,
    p,
    li {
      font-size: 12px;
    }
    ul {
      li {
        margin-right: 15px;
        &:after {
          right: -7.5px;
        }
      }
    }
    .inner {
      .info {
        li {
          margin-right: 20px;
          &:after {
            display: none;
          }
        }
      }
    }
  }
} //break point 680px

@media screen and (max-width: 420px) {
} //break point 420px
